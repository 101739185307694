import BounceLoader from 'react-spinners/BounceLoader'

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};

export default function MyLoader({ active, color }) {

    return (
        <div className="loading-container">
            <BounceLoader
                color={color}
                loading={active}
                cssOverride={override}
                size={50}
                aria-label="Loading"
                data-testid="loader"
                speedMultiplier={1}
            />
        </div>
    )
}