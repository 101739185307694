import { createContext, useState } from "react";

export const ConfiguratoreContext = createContext({
    //consuntivazione attivita
    serrande: [],
    setSerrandeCtx: () => { },
    canotti: [],
    setCanottiCtx: () => { },
    stacchi: [],
    setStacchiCtx: () => { },
    flgPlenum: false,
    setFlgPlenumCtx: () => {},
    configurazione1: [],
    setConfigurazione1Ctx: () => { },
});

function ConfiguratoreContextProvider({ children }) {
    const [serrande, setSerrande] = useState({
        zona1: {
            serranda1: '',
            serranda2: '',
        },
        zona2: {
            serranda1: '',
            serranda2: '',
        },
        zona3: {
            serranda1: '',
            serranda2: '',
        },
        zona4: {
            serranda1: '',
            serranda2: '',
        },
        zona5: {
            serranda1: '',
            serranda2: '',
        },
        zona6: {
            serranda1: '',
            serranda2: '',
        },
        zona7: {
            serranda1: '',
            serranda2: '',
        },
    });
    const [canotti, setCanotti] = useState({
        zona1: {
            canotto: '',
        },
        zona2: {
            canotto: '',
        },
        zona3: {
            canotto: '',
        },
        zona4: {
            canotto: '',
        },
        zona5: {
            canotto: '',
        },
        zona6: {
            canotto: '',
        },
        zona7: {
            canotto: '',
        },
    });
    const [stacchi, setStacchi] = useState({
        zona1: 1,
        zona2: 1,
        zona3: 1,
        zona4: 1,
        zona5: 1,
        zona6: 1,
    });
    const [flgPlenum, setFlgPlenum] = useState(false);
    const [configurazione1, setConfigurazione1] = useState({
        kitCategory: '',
        codiceFinaleKIT: '',
        elencoFinaleComponenti: {},
    })

    function setFlgPlenumCtx(flg){
        setFlgPlenum(flg);
    }

    function setSerrandeCtx(serrandeLette) {
        setSerrande(serrandeLette);
    }

    function setCanottiCtx(canottiLetti) {
        setCanotti(canottiLetti);
    }

    function setStacchiCtx(stacchiLetti) {
        setStacchi(stacchiLetti);
    }

    function setConfigurazione1Ctx(configurazioneLetta) {
        setConfigurazione1(configurazioneLetta);
    }

    const value = {
        serrande: serrande,
        setSerrandeCtx: setSerrandeCtx,
        canotti: canotti,
        setCanottiCtx: setCanottiCtx,
        stacchi: stacchi,
        setStacchiCtx: setStacchiCtx,
        flgPlenum: flgPlenum,
        setFlgPlenumCtx: setFlgPlenumCtx,
        configurazione1: configurazione1,
        setConfigurazione1Ctx: setConfigurazione1Ctx,
    }

    return <ConfiguratoreContext.Provider value={value} >{children}</ConfiguratoreContext.Provider>
}

export default ConfiguratoreContextProvider;