import React, { useState, forwardRef, useRef, useEffect, useImperativeHandle  } from 'react';

const Canvas = forwardRef(({
    width, step, coloreComandi, tipoSistema, tipoImpianto, numberOfZones, 
    numTotaleStacchi, numLocaliPavRadiante, tipoInterfaccia, flgPavimentoRadiante, 
    portataMax, flgKitSani
}, ref) => {

    const canvasRef = useRef(null);
    const [maxHeight, setMaxHeight] = useState(0);
    const [draw, setDraw] = useState(false); // Track if height is set
    const [scale, setScale] = useState(1);
    const [myWidth, setMyWidth] = useState(0);
    const factor = 3000 / width; // 3000 indica la larghezza del file .png generato. Diminuire se è troppo pesante

    const exportCanvas = () => {
        const canvas = canvasRef.current;
        return canvas.toDataURL('image/png');
    };

    // Expose the exportCanvas function to the parent component
    useImperativeHandle(ref, () => ({
        exportCanvas
    }));

    useEffect(() => {
        setScale(width * factor / 1000);
        setMyWidth(width);
    }, [width])

    useEffect(() => {
        const s = value => value * scale;
        let h = s(200);
        if (numTotaleStacchi < 4) h += s(15) + s(45 * 4);
        else h += s(15) + s(45 * numTotaleStacchi);
        if (flgPavimentoRadiante && s(200) + s(50 * numLocaliPavRadiante) - s(5) > h) {
            h = s(200) + s(50 * numLocaliPavRadiante) - s(5);
        }
        if (tipoInterfaccia !== '' && h < s(450)) h = s(450); // caldaia
        if (flgKitSani && step >= 8 && h < s(480)) h = s(480);

        setMaxHeight(h/factor);
    }, [step, coloreComandi, tipoSistema, tipoImpianto, numberOfZones, numTotaleStacchi, numLocaliPavRadiante, tipoInterfaccia, flgPavimentoRadiante, portataMax, flgKitSani, scale, factor]);

    useEffect(() => {
        setDraw(true);
    }, [step, coloreComandi, tipoSistema, tipoImpianto, numberOfZones, numTotaleStacchi, numLocaliPavRadiante, tipoInterfaccia, flgPavimentoRadiante, portataMax, flgKitSani, scale, maxHeight]);

    useEffect(() => {

        if (!draw) return; // Only draw if the height has been set
        setDraw(false);
        const canvas = canvasRef.current;
        
        // Scale function
        const s = value => value * scale;
        canvas.width = 1000 * scale;
        canvas.height = maxHeight * scale * 1000 / myWidth;

        const ctx = canvas.getContext('2d');
        const path = 'componenti/';

        // Clear the canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Helper function to load and draw images
        const loadImage = (src, onload) => {
            const img = new Image();
            img.src = src;
            img.onload = () => onload(img);
        };

        // immagine app
        loadImage(path + 'ICONA_APP.jpg', (img) => {
            ctx.drawImage(img, 0, 0, s(110), s(150));
        });

        // scheda
        loadImage(path + 'KN-UNI-WIFI.jpg', (img) => {
            ctx.drawImage(img, s(315), s(30), s(100), s(78));
        });

        // master
        const masterSrc = coloreComandi === 'neri' ? 'KN-ETERNAL-N.png' : 'KB-ETERNAL-M.png';
        loadImage(path + masterSrc, (img) => {
            ctx.drawImage(img, s(430), s(100), s(75), s(50));
        });

        // slave
        let numComandi = flgPavimentoRadiante ?
            numLocaliPavRadiante >= numberOfZones ?
                numLocaliPavRadiante
                : numberOfZones
            : numberOfZones;

        const slaveSrc = coloreComandi === 'neri' ? 'KN-SMART-S.png' : 'KB-SMART-S.png';
        loadImage(path + slaveSrc, (img) => {
            for (let i = 1; i < numComandi; i++) {
                ctx.drawImage(img, s(525 + ((i - 1) * 70)), s(100), s(55), s(50));
            }
        });

        // icona radio
        const radioFiloSrc = tipoImpianto === "RADIO" ? 'ICONA_RADIO.jpg' : 'ICONA_FILO.jpg';
        loadImage(path + radioFiloSrc, (img) => {
            let w, h, x_master, y_master, x_slave;

            if (tipoImpianto === "RADIO") {
                w = s(29);
                h = s(30);
                x_master = s(430);
                y_master = s(57);
                x_slave = s(525);
                ctx.drawImage(img, s(380), s(0), w, h);
                //prima icona sopra a centralina più piccola - rimetto poi dimensioni corrette
                w = s(35);
                h = s(36);
            } else {
                w = s(26);
                h = s(17);
                x_master = s(474);
                y_master = s(75);
                x_slave = s(550);

                ctx.beginPath();
                ctx.strokeStyle = 'red';
                ctx.lineWidth = factor;

                ctx.moveTo(s(328), s(105));
                ctx.lineTo(s(328), s(130));
                ctx.lineTo(s(420), s(130));
                ctx.lineTo(s(420), s(65));
                ctx.lineTo(s(440), s(65));
                ctx.lineTo(s(440), s(101));
                ctx.lineTo(s(440), s(65));
            }

            ctx.drawImage(img, x_master, y_master, w, h);

            for (let i = 1; i < numComandi; i++) {
                ctx.drawImage(img, x_slave + ((i - 1) * s(70)), y_master, w, h);

                if (tipoImpianto === "FILO") {
                    ctx.lineTo(x_slave + ((i - 1) * s(70)) - s(15), s(65));
                    ctx.lineTo(x_slave + ((i - 1) * s(70)) - s(15), s(101));
                    ctx.lineTo(x_slave + ((i - 1) * s(70)) - s(15), s(65));
                }
            }

            if (tipoImpianto === "FILO") {
                ctx.stroke();
                ctx.closePath();
            }
        });

        // disegno rettangoli con scritta
        let x = s(245);
        let y = s(200);
        let width = s(70);
        let width2 = s(100);
        let height = numTotaleStacchi < 4 ? s(15 + (45 * 4)) : s(15 + (45 * numTotaleStacchi));

        ctx.strokeStyle = 'black';
        ctx.lineWidth = factor;
        ctx.strokeRect(x, y, width, height);
        ctx.strokeRect(x + width, y, width2, height);

        // Rotate the canvas
        ctx.save();
        ctx.translate(x + width / 2, y + height / 2);
        ctx.rotate((-90 * Math.PI) / 180);

        // Draw the text
        ctx.fillStyle = 'black';
        ctx.font = `${s(16)}px Arial`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        let text = "Unità interna\ne plenum";
        const lines = text.split('\n');
        lines.forEach((line, index) => {
            ctx.fillText(line, 0, (index - lines.length / 2 + 0.5) * s(20));
        });
        ctx.restore();

        if (flgKitSani && step >= 8) {
            // immagine botola
            let x_pos = x;
            loadImage(path + 'botola_KIT-SANI.png', (img) => {
                if (portataMax > 1600) {
                    ctx.drawImage(img, x_pos + width + s(14), y + s(25), s(32), s(50));
                    ctx.drawImage(img, x_pos + width + s(14), y + height - s(25) - s(50), s(32), s(50));
                } else {
                    ctx.drawImage(img, x_pos + width + s(14), y + (height / 2) - s(25), s(32), s(50));
                }
            });

            loadImage(path + 'ICONA_IMG-FONDO_KITSANIRisorsa 1.jpg', (img) => {
                ctx.drawImage(img, s(160), s(410), s(70), s(70));

                // riga 1
                ctx.beginPath();
                ctx.strokeStyle = 'grey';
                ctx.lineWidth = factor;
                ctx.moveTo(s(230), s(445));
                if (portataMax > 1600) ctx.lineTo(x_pos + width + s(14), y + height - s(25) - s(25));
                else ctx.lineTo(x_pos + width + s(14), y + (height / 2));
                ctx.stroke();
                ctx.closePath();
                // riga 2
                ctx.beginPath();
                ctx.strokeStyle = 'red';
                ctx.lineWidth = factor;
                ctx.moveTo(s(325.5), s(105));
                ctx.lineTo(s(325.5), s(190));
                ctx.lineTo(s(195), s(190));
                ctx.lineTo(s(195), s(410));
                ctx.stroke();
                ctx.closePath();
            });
        }

        // tubi
        x = x + width + width2 + s(1);
        loadImage(path + 'ICONA_SERRANDA_TUBO_PORTABOCCHETTA_CORTO.jpg', (img) => {
            for (let i = 0; i < numTotaleStacchi; i++) {
                let tmp_y = 0;
                if (numTotaleStacchi == 2 && i == 1) tmp_y = y + s(15 + 3 * 45);
                else if (numTotaleStacchi == 3 && i == 1) tmp_y = y + s(15 + 1.5 * 45);
                else if (numTotaleStacchi == 3 && i == 2) tmp_y = y + s(15 + 3 * 45);
                else tmp_y = y + s(15 + i * 45);
                ctx.drawImage(img, x, tmp_y, s(133), s(30));

                ctx.beginPath();
                if (tipoImpianto == "FILO") {
                    ctx.setLineDash([s(5), s(5)]);
                    ctx.strokeStyle = 'grey';
                } else {
                    ctx.strokeStyle = 'red';
                }
                ctx.lineWidth = factor;
                ctx.moveTo(x, tmp_y + s(25));
                ctx.lineTo(x - s(10) - i * s(7), tmp_y + s(25));
                ctx.lineTo(x - s(10) - i * s(7), s(105));
                ctx.stroke();
                ctx.setLineDash([]);
                ctx.closePath();
            }
        });

        // interfaccia
        if (tipoInterfaccia !== '') {
            let nomeInt = '';
            if (tipoInterfaccia === 'FILO') nomeInt = 'NH-CFI_no_brand.jpg';
            else if (tipoInterfaccia === 'FANCOIL') nomeInt = 'NH-GTP-UNI-2.jpg';
            else if (tipoInterfaccia === 'INFRAROSSO') nomeInt = 'INFRAROSSO.jpg';

            loadImage(path + nomeInt, (img) => {
                if (tipoInterfaccia === 'INFRAROSSO') ctx.drawImage(img, s(213), s(78), s(70), s(52));
                else ctx.drawImage(img, s(240), s(35), s(50), s(75));

                ctx.beginPath();
                ctx.strokeStyle = 'grey';
                ctx.lineWidth = factor;
                ctx.moveTo(s(280), s(105));
                ctx.lineTo(s(325), s(105));
                ctx.stroke();
                ctx.closePath();
            });

            if (flgPavimentoRadiante) {
                loadImage(path + 'ICONA_CALDAIA.jpg', (img) => {
                    ctx.drawImage(img, 0, s(330), s(86), s(120));
                });

                loadImage(path + 'NH-SR-UNI.jpg', (img) => {
                    ctx.drawImage(img, s(100), s(180), s(70), s(101));

                    for (let i = 0; i < numTotaleStacchi; i++) {
                        ctx.beginPath();
                        ctx.strokeStyle = 'grey';
                        ctx.lineWidth = factor;
                        ctx.moveTo(s(118) + s(7) * i, s(330));
                        ctx.lineTo(s(118) + s(7) * i, s(270));
                        ctx.stroke();
                        ctx.closePath();
                    }
                });

                loadImage(path + 'ICONA_ELETTROVALVOLE-TESTINE_PANNELLO_RADIANTE.jpg', (img) => {
                    ctx.drawImage(img, s(100), s(330), s(80), s(50));
                });

                ctx.beginPath();
                ctx.strokeStyle = 'black';
                ctx.lineWidth = factor;
                ctx.moveTo(s(43), s(330));
                ctx.lineTo(s(43), s(170));
                ctx.lineTo(s(120), s(170));
                ctx.lineTo(s(120), s(180));
                ctx.stroke();
                ctx.closePath();

                ctx.beginPath();
                ctx.strokeStyle = 'blue';
                ctx.lineWidth = factor;
                ctx.moveTo(s(135), s(180));
                ctx.lineTo(s(135), s(170));
                ctx.lineTo(s(323), s(170));
                ctx.lineTo(s(323), s(105));
                ctx.stroke();
                ctx.closePath();

                for (let i = 0; i < numLocaliPavRadiante; i++) {
                    loadImage(path + `ICONA_RADIANTE_ZONA${i + 1}.jpg`, (img) => {
                        ctx.drawImage(img, s(620), s(200) + s(50) * i, s(201), s(45));
                    });
                }
            }
        }
    }, [draw]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <canvas ref={canvasRef} style={{ marginTop: '10px', width: width, height: maxHeight }}></canvas>
        </div>
    );
});

export default Canvas;