import React, { memo, useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { ConfiguratoreContext } from '../../store/configuratore-context';

export default memo(({ title, zoneIdentifier, isDisabled, numberOfZones }) => {

    const configuratoreCtx = useContext(ConfiguratoreContext);

    const stacchiOptions = [
        { value: '1', label: '1 STACCO' },
        { value: '2', label: '2 STACCHI' },
    ];

    const [stacchi, setStacchi] = useState({ value: '', label: '' });

    const handleStacchiChange = (selectedOption) => {
        setStacchi(selectedOption);
        configuratoreCtx.setStacchiCtx({
            zona1: zoneIdentifier == 'zona1' ? selectedOption.value : configuratoreCtx.stacchi.zona1,
            zona2: zoneIdentifier == 'zona2' ? selectedOption.value : configuratoreCtx.stacchi.zona2,
            zona3: zoneIdentifier == 'zona3' ? selectedOption.value : configuratoreCtx.stacchi.zona3,
            zona4: zoneIdentifier == 'zona4' ? selectedOption.value : configuratoreCtx.stacchi.zona4,
            zona5: zoneIdentifier == 'zona5' ? selectedOption.value : configuratoreCtx.stacchi.zona5,
            zona6: zoneIdentifier == 'zona6' ? selectedOption.value : configuratoreCtx.stacchi.zona6,
        });
    };

    //aggiornamento stacchi di default al cambio zone
    useEffect(() => {
        setStacchi({ value: 1, label: '1 STACCO' });
    }, [numberOfZones]);

    return (
        <label className='select-container' style={{minWidth: '160px', maxWidth: '300px'}}>
            {title}
            <Select
                className='select-item-row'
                options={stacchiOptions.filter(option => option.value !== '')} //per  gli stacchi non c'è l'opzione vuota
                value={stacchi}
                onChange={handleStacchiChange}
                isSearchable={true}
                placeholder="Seleziona il numero di stacchi"
                isDisabled={isDisabled}
            />
        </label>
    );
});
