import React from 'react';
import '../Configurator.css'; // Importa il file CSS per lo stile
import { BsArrowRightShort, BsArrowLeftShort, BsArrowClockwise } from "react-icons/bs";

const ProgressBar = ({ step, totalSteps, handleNext, handlePrevious, flgConf2, kitCategory }) => {
    if (step === 100) step = totalSteps;
    else step -= 1;
    const progressPercentage = step > 0 ? ((step / totalSteps) * 100) : 0;

    return (
        <div style={{ display: 'flex', alignItems: 'row', alignItems: 'center' }}>
            <button className={(step < 6 && flgConf2 && kitCategory == 'kitMotorizzato') || (step < 6 && flgConf2 && kitCategory == 'kitDistribuzione')
                || step == -1 || step == 100 ? "icon-button invisible" : "icon-button"} onClick={(step < 6 && flgConf2) || step == 100 ? null : handlePrevious}>
                <BsArrowLeftShort className="icon" />
            </button>
            <div className="progress-bar">
                {
                    step > 0 ?
                        <div className="progress-label" style={{ left: `${progressPercentage}%` }}>
                            {step} di {totalSteps}
                        </div>
                        : null
                }
                <div className="progress-bar-fill" style={{ width: `${progressPercentage}%` }} />
            </div>
            <button className="icon-button" onClick={handleNext}>
                <BsArrowRightShort className="icon" />
            </button>
            {
                false &&
                <button
                    className="button right"
                    style={{ marginLeft: '15px' }}
                    onClick={() => window.location.reload()}>
                    Ricomincia
                    <BsArrowClockwise className="text-icon-right" />
                </button>
            }
        </div>
    );
};

export default ProgressBar;