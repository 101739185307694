import { useEffect } from "react";
import Configurator from "./Configurator";
import ConfiguratoreContextProvider from "./store/configuratore-context";


const App = () => {

  useEffect(() => {
    // Rimuovi la cronologia della sessione
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = function (event) {
      window.history.go(1);
    };
  }, []);

  return (
    <div className='app' id="app">
      <ConfiguratoreContextProvider>
        <Configurator />
      </ConfiguratoreContextProvider>
    </div>
  );
}

export default App;