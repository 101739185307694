import React, { memo, useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { ConfiguratoreContext } from '../../store/configuratore-context';

export default memo(({ canottoDefault, title, zoneIdentifier }) => {

    const configuratoreCtx = useContext(ConfiguratoreContext);

    const canottoOptions = [
        { value: '', label: '' },
        { value: 'CANOTTO150', label: 'CANOTTO150' },
        { value: 'CANOTTO200', label: 'CANOTTO200' },
    ];

    const [canotto, setCanotto] = useState({ value: '', label: '' });

    const handleCanottoChange = (selectedOption) => {
        setCanotto(selectedOption);
        configuratoreCtx.setCanottiCtx({
            zona1: {
                canotto: zoneIdentifier == 'zona1' ? selectedOption.value : configuratoreCtx.canotti.zona1.canotto,
            },
            zona2: {
                canotto: zoneIdentifier == 'zona2' ? selectedOption.value : configuratoreCtx.canotti.zona2.canotto,
            },
            zona3: {
                canotto: zoneIdentifier == 'zona3' ? selectedOption.value : configuratoreCtx.canotti.zona3.canotto,
            },
            zona4: {
                canotto: zoneIdentifier == 'zona4' ? selectedOption.value : configuratoreCtx.canotti.zona4.canotto,
            },
            zona5: {
                canotto: zoneIdentifier == 'zona5' ? selectedOption.value : configuratoreCtx.canotti.zona5.canotto,
            },
            zona6: {
                canotto: zoneIdentifier == 'zona6' ? selectedOption.value : configuratoreCtx.canotti.zona6.canotto,
            },
            zona7: {
                canotto: zoneIdentifier == 'zona7' ? selectedOption.value : configuratoreCtx.canotti.zona7.canotto,
            },
        });
    };

    //aggiornamento canotto di default al cambio zone
    useEffect(() => {
        setCanotto({ value: canottoDefault, label: canottoDefault });
    }, [canottoDefault]);

    return (
        <div style={{ width: '200px', height: '70px', marginTop: '5px' }}>            
            <div style={{}}>
                <label className='label-text'>
                    {title}
                </label>
                <Select
                    className='select-item-flow'
                    options={canottoOptions.filter(option => option.value !== '')} //per la canotto non c'è l'opzione vuota
                    value={canotto}
                    onChange={handleCanottoChange}
                    isSearchable={true}
                    placeholder="Seleziona la serranda"
                    isDisabled={false}
                />
            </div>
        </div>
    );
});
